@import url('https://fonts.googleapis.com/css?family=Roboto:300,300italic,700,700italic');
@import "normalize.css";
@import "milligram/dist/milligram.min.css";

body, html {
  height: 100%;
}

body {
  margin: 0;
  background-color: #235884;
  font-family: 'Roboto', sans-serif;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

div{
  margin: auto;
}

h1,h3 {
  display: inline;
}
